<template>
    <div id="my-calls" class="page">
      <b-button variant="primary" size="sm" class="mb-3" v-if="user && user.hasPermission('rtc:calls.search')" :to='{name: "callcenter.calls"}'>All Calls</b-button>
      <CallsCardTemplate title="My Calls" showPagination="true" handler="userCalls" :user="user" :loggedUser="user"/>
    </div>
</template>

<script>
import CallsCardTemplate from './../components/Calls/Calls.Card.Template';
export default {
    props: ['user'],
    components: {
      CallsCardTemplate
    },
    methods:{
      notifyParent(){
        let data = {active: "myCalls"};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },
    },

    mounted(){
        this.notifyParent();
    },
};
</script>
